/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:984de140-467a-4ec1-8f6b-e8a463e5aee6",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ZpfTvpUIa",
    "aws_user_pools_web_client_id": "694emdctbaqvm7fmmb72976mv8",
    "oauth": {
        "domain": "iyo-jarvis-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:8080/,https://dev.iyo-jarvis.com/,https://www.iyo-jarvis.com/,https://iyo-jarvis.com/,https://prod.iyo-jarvis.com/",
        "redirectSignOut": "https://dev.iyo-jarvis.com/,https://www.iyo-jarvis.com/,https://iyo-jarvis.com/,https://prod.iyo-jarvis.com/,http://localhost:8080/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "12",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "testsite-storage-e3a6569b122823-prod",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "SharingDB-prod",
            "region": "us-west-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "DialogueApi",
            "endpoint": "https://3isadythyj.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://z094hoff55.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_mobile_analytics_app_id": "7efeaaecf3ca49369ff447b132ba4bfb",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
