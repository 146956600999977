const awsauth_prod = {
    region: 'us-west-2',
    userPoolId: 'us-west-2_ZpfTvpUIa',
    userPoolWebClientId: '694emdctbaqvm7fmmb72976mv8',
    mandatorySignIn: false,
    oauth: {
      domain: 'login.iyo-jarvis.com',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: 'http://localhost:8080/',
      redirectSignOut: 'http://localhost:8080/',
      responseType: 'code',
    }
}

const awsauth_dev = {
  region: 'us-west-2',
  userPoolId: 'us-west-2_YL36YJX6A',
  userPoolWebClientId: 'au19goekjodsl5rot6ki69in5',
  mandatorySignIn: false,
  oauth: {
    domain: 'devlogin.iyo-jarvis.com',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: 'http://localhost:8080/',
    redirectSignOut: 'http://localhost:8080/',
    responseType: 'code',
  }
}

export {awsauth_prod, awsauth_dev};
