import { Amplify, Auth, Hub, API, Storage, Analytics } from 'aws-amplify';

import awsconfig from './aws-exports';
import { awsauth_dev, awsauth_prod }  from './aws-auth';

import 'jquery'
import './custom.scss'
import 'bootstrap'
import './styles.css'
import './chat.css'
import './pong-loader.css'

import LongOperationManager from './longoperationmanager'

let isProduction = (process.env.NODE_ENV === 'production')
console.log(`MODE = ${process.env.NODE_ENV}`);

let awsauth = isProduction ? awsauth_prod : awsauth_dev

awsconfig.oauth={}
Amplify.configure(awsconfig);
awsauth.oauth.redirectSignIn = `${window.location.origin}/`;
awsauth.oauth.redirectSignOut = `${window.location.origin}/`;
const currentConfig = Auth.configure(awsauth);
Storage.configure({ level: 'private' });

let currentAuthenticatedUser = null;
let currentConversationId = null;
let currentSharedConversationId = null;
let ratingEnabled = false;
let lastMessageId = 0;

let longOperationManager = new LongOperationManager("BusySpinnerOverlay", 3000)


function ParseChatFromPlainText(text) {
    let lines = []
    let dialogue = null
    let array = text.split(/(human:|jarvis:)/i);
    let id = 0
    array.forEach((element, index) => {
        let line = element.trim()
        if (line.toLowerCase() === 'human:')
            dialogue = { AI: false, author: 'human', text: '' }
        else if (line.toLowerCase() === 'jarvis:')
            dialogue = { AI: true, author: 'jarvis', text: '' }
        else if (dialogue)
        {
            dialogue.text = line
            dialogue.id = ++id
            lines.push (dialogue)
        }
        else
            console.log(`dialogue line ignored "${line}"`)
    });
    return lines
}

function ParseChatFromJson(text) {
    return JSON.parse(text)
}

async function PopulateChatFromFile(file_key, shared = false) {
    console.log(`Populating chat from ${file_key}...`)

    let publicprivate = shared ? 'public' : 'private'

    // fetch conversation file
    Storage.get(file_key, { download: true, cacheControl: 'no-cache', level: publicprivate }).then(result => {
        // data.Body is a Blob
        result.Body.text().then(string => {
            // add each line to the HTML
            let lines = null
            try {
                lines = ParseChatFromJson(string)['conversation']
                console.log('loaded conversation from JSON')
            } catch (e) {
                lines = ParseChatFromPlainText(string)
                console.log('loaded conversation from plain text')
            }
            ClearChat()
            $.each(lines, function (index, data) {
                AddToChat(data.AI, data.text, data.timestamp, data.id, data.rating)
            });
            InitializeChatHandlers()
        });
    }).catch(error => {
        console.log(error)
    })
}

async function PopulateChat(conversationId) {
    console.log(`Populating chat ${conversationId}...`)

    let spinner = longOperationManager.begin()
    API.get('DialogueApi', `/conversations/id/${conversationId}`).then(async function (response) {
        console.log(response)
        await PopulateChatFromFile(response.file_key)
        currentConversationId = conversationId;
        $("#MessageInput").trigger('focus')
        spinner.end()
    })
}

function GetRatingMenuHtml(rating) {
    return [`<i class="${rating > 0 ? 'far fa-thumbs-up' : rating < 0 ? 'far fa-thumbs-down' : 'far fa-question-circle'}"></i>`,
    rating > 0 ? 'thumbs-up' : rating < 0 ? 'thumbs-down' : 'thumbs-unrated']
}

function ClearChat() {
    $("#Conversation").html('')
    lastMessageId = 0
}

function AddToChat(incoming, content, timestamp = undefined, id = undefined, rating = 0) {
    let timeHTML = timestamp ? `<span class="time_date"> ${TimeStampToAbsoluteString(timestamp)} </span>` : ''

    // if no id is provided, use lastMessageId
    if (!id)
        id = ++lastMessageId
    else
        if (id > lastMessageId)
            lastMessageId = id

    // Also store index from id as an HTML property so we can find it later
    let index_data = `data-index="${id}"`

    if (incoming)
    {
        let ratingHTML = ''
        if (ratingEnabled)
        {
            let [ratingIcon, ratingVisible] = GetRatingMenuHtml(rating)
            ratingHTML =`
            <div class="ratingpopup">
                <button class="btn btn-sm btn-outline-secondary dropdown-toggle ${ratingVisible}" type="button" data-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                ${ratingIcon}
                </button>
                <div class="dropdown-menu p-0 m-0" aria-label="Rating">
                <div class="btn-group btn-group-sm" aria-label="Rating">
                <button class="btn btn-outline-danger btn-sm ratedown">
                    <i class="far fa-thumbs-down"></i></button>
                <button class="btn btn-outline-secondary btn-sm active rateneutral">
                    <i class="fas fa-ban"></i>
                <button class="btn btn-outline-success btn-sm rateup">
                    <i class="far fa-thumbs-up"></i></button>
                </div>
                </div>
            </div>`
        }

        $("#Conversation").append(`
<div class="incoming_msg" ${index_data}>
    <div class="incoming_msg_img"> <i class="fas fa-robot"></i>${ratingHTML}</div>
    <div class="received_msg received_width_msg">
        <p>${content.replace(/[\n\r]/g,'</p><p>')}</p>
        ${timeHTML}
    </div>
</div>`)
    }
    else
    {
        $("#Conversation").append(`
<div class="outgoing_msg" ${index_data}>
    <div class="sent_msg">
        <p>${content.replace(/[\n\r]/g,'</p><p>')}</p>
        ${timeHTML}
    </div>
</div>`)
    }
}

function InitializeChatHandlers() {
    if (ratingEnabled)
    {
        $('#Conversation .rateup, #Conversation .rateneutral, #Conversation .ratedown').off('click').on('click',
            async function (event) {
                if (!currentConversationId)
                    return

                let id = $(this).closest('.incoming_msg').data('index')
                let rating = $(this).hasClass('rateup') ? 1 : $(this).hasClass('ratedown') ? -1 : 0
                console.log(`Rating ${id} with ${rating}...`)

                let [ratingIcon, ratingVisible] = GetRatingMenuHtml(rating)

                let menu_button = $(this).closest('.ratingpopup').children('.dropdown-toggle')
                menu_button.html(ratingIcon)
                menu_button.removeClass('thumbs-unrated thumbs-up thumbs-down')
                menu_button.addClass(ratingVisible)

                let spinner = longOperationManager.begin()
                API.post('DialogueApi',
                    `/conversations/rating/${currentConversationId}/${id}`,
                    { body: { rating: rating } }
                ).then(async function (response) {
                    console.log(response)
                }).finally(() => {
                    spinner.end()
                })
            }
        )
    }
}


function ScrollToEndOfChat() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    // $("#Conversation")[0].scrollIntoView({
    //     behavior: "smooth", // or "auto" or "instant"
    //     block: "end" // or "end"
    // });
}

async function ChangeCurrentConversationTo(conversationId) {
    console.log(`Changing current conversation to ${conversationId}...`)
    Analytics.record({
        name: 'conversationVisit',
        attributes: { type: 'private', id: conversationId }
    });
    currentSharedConversationId = null
    // we can rate private conversations when not in production
    ratingEnabled = !isProduction
    window.history.replaceState({}, "", `${window.location.origin}?convo=${conversationId}`)
    $('#ConversationList .chat_list').removeClass('active_chat')
    $(`#ConversationList .chat_list[data-conversation-id="${conversationId}"]`).addClass('active_chat')
    await PopulateChat(conversationId)
    ScrollToEndOfChat()
    AllowInput(true)
}

async function ChangeCurrentConversationToShared(sharedConversationId) {
    console.log(`Changing shared conversation to ${sharedConversationId}...`)
    Analytics.record({
        name: 'conversationVisit',
        attributes: { type: 'shared', id: sharedConversationId }
    });
    currentConversationId = null
    // we can never rate shared conversations
    ratingEnabled = false
    window.history.replaceState({}, "", `${window.location.origin}?shared=${sharedConversationId}`)
    AllowInput(false)
    await PopulateChatFromFile('conversations/' + sharedConversationId, true)
    ScrollToEndOfChat()
}

function InitializeListHandlers() {
    // mutually exclusive
    $('#ConversationList .chat_list').off('click').on('click', async function (event) {
        event.preventDefault()
        let id = $(this).data('conversationId')
        // dismiss popup immediatelly
        $("#ConversationHistoryModal").modal("hide");
        await ChangeCurrentConversationTo(id)
    })
}

// input is a posix timestamp (therefore, in SECONDS)
function TimeStampToRelativeString(timestamp) {
    const units = [
        ["year", 31536000],
        ["month", 2628000],
        ["day", 86400],
        ["hour", 3600],
        ["minute", 60],
        ["second", 1],
    ];

    let elapsed = timestamp - Date.now()/1000   // now is in ms

    const rtf = new Intl.RelativeTimeFormat(navigator.language, { style: "narrow" });
    for (const [unit, amount] of units) {
        if (Math.abs(elapsed) > amount || unit === "second") {
            return rtf.format(Math.round(elapsed / amount), unit);
        }
    }
    console.assert(false,"unreachable")
}

// input is a posix timestamp (therefore, in SECONDS)
function TimeStampToAbsoluteString(timestamp) {
    return new Date(timestamp * 1000).toLocaleString()
}

function GenerateConversationHTML(data, index) {
    let when = TimeStampToRelativeString(data.timestamp)
    return `
    <div id=${"conversation"+index} data-conversation-id=${data.id} class="chat_list chat_people ${(index == 0) ? "active_chat" : ""}">
        <div class="chat_img"> <i class="fa fa-comment-dots"></i> </div>
        <div class="chat_ib">
        <h5>${data.id}<span class="chat_date">${when}</span></h5>
        <p>Conversation about x y z</p>
        </div>
    </div>`;
}

async function PopulateConversations() {
    return await API.get('DialogueApi', '/conversations').then(async function (response) {
        let listNode = $("#ConversationList");
        let listHTML = '';
        let topID = null;
        $.each(response.conversations, function (index, data) {
            listHTML = GenerateConversationHTML(data, index) + listHTML
            topID = data.id
        });
        listNode.html(listHTML);
        InitializeListHandlers();
        return topID
    })
}


async function UpdateLoggedIn() {
    // update user name
    $('[id=LoggedInUser]').text(currentAuthenticatedUser.signInUserSession.idToken.payload.email)
    if (currentAuthenticatedUser.isApproved)
    {
        $('#AlertUserNotApproved').addClass("d-none")

        // enable and populate other UX elements

        let lastid = await PopulateConversations();

        // if we have a shared conversation id in the url, go to it
        // otherwise, if we have a private conversation id in the url, go to it
        // otherwise, go to the last conversation
        // otherwise, start a new conversation
        if (currentSharedConversationId)
        {
            await ChangeCurrentConversationToShared(currentSharedConversationId)
        }
        else if (currentConversationId)
        {
            await ChangeCurrentConversationTo(currentConversationId)
        }
        else if (lastid)
        {
            await ChangeCurrentConversationTo(lastid)
        }
        else
        {
            // TODO: no conversations? create a default one or show a message
        }
    }
    else
    {
        $('#AlertUserNotApproved').removeClass("d-none")
    }
}


async function FindIfCurrentUserApproved() {
    return (async function() {
        try {
            await API.get('DialogueApi', '/approveduser')
            return true
        }
        catch(e){
            return false
        }
    }())
}

async function FetchCurrentUserState() {
    if (currentAuthenticatedUser === null)
        return null;
    else
    {
        currentAuthenticatedUser.isAuthenticated = true
        currentAuthenticatedUser.isApproved = await FindIfCurrentUserApproved()
    }
}

async function UpdateLoggedInState(user) {
    const isLoggedIn = (user !== null);

    currentAuthenticatedUser = user

    // when you are logged in, the logged in user control should be displayed
    if (isLoggedIn != $('#LoggedInUser').hasClass("d-none"))
    {
        await FetchCurrentUserState()
        if (isLoggedIn)
        {
            $('.pre-signin').addClass("d-none")
            $('.post-signin').removeClass("d-none")
            await UpdateLoggedIn();
        }
        else
        {
            $('.post-signin').addClass("d-none")
            $('.pre-signin').removeClass("d-none")
        }
    }
}

function AllowInput(enable) {
    if (enable)
    {
        $("#MessageInput").prop("disabled", false)
        $("#SendButton").prop("disabled", false)
        $("#MessageInput").trigger('focus')
    }
    else
    {
        $("#MessageInput").prop("disabled", true)
        $("#SendButton").prop("disabled", true)
    }
}

function IsValidSharedConversationID(id) {
    // check that it is 1 to 8 base 36 lowercase characters
    return /^[a-z0-9]{1,8}$/.test(id)
}

function IsValidPrivateConversationID(id) {
    // check that it is one or more digits followed by a '-' followed by one or more digits
    return /^\d+-\d+$/.test(id)
}

window.onload = async function() {
    const origLocation = window.location
    const urlParams = new URLSearchParams(origLocation.search);
    let id = urlParams.get('shared');  // null if not a shared conversation url
    if (id && IsValidSharedConversationID(id))
    {
        currentSharedConversationId = id
        currentConversationId = null
    }
    else
    {
        currentSharedConversationId = null
        id = urlParams.get('convo');  // null if not a conversation url
        if (id && IsValidPrivateConversationID(id))
            currentConversationId = id
    }

    if (!isProduction)
        $('#Debug').append('<i class="fab fa-dev"></i>')

    // if (currentSharedConversationId)
    // {
    //     // keep our root site on the stack and redirect to the shared conversation, so back gets you back to the original site
    //     window.history.replaceState({}, "", origLocation.pathname);
    //     window.history.pushState({}, "", origLocation);
    // }

    $('[id=GetStartedButton]').on("click", function(e) {
        console.log("GetStartedButton button clicked")
        Auth.currentAuthenticatedUser().then(user => {
            console.log('currentAuthenticatedUser', user)
            return true;
          }).catch(() => {
            console.log('Not signed in')
            Auth.federatedSignIn()
            return false;    // true to follow link
        })
    })

    $('[id=LoginButton]').on("click", function(e) {
        console.log("LoginButton button clicked")
        Auth.currentAuthenticatedUser().then(user => {
            console.log('currentAuthenticatedUser', user)
            return true;
          }).catch(() => {
            console.log('Not signed in')
            Auth.federatedSignIn()
            return false;    // true to follow link
        })
    })

    $('[id=LogoutButton]').on("click", async function(e) {
        console.log("LogoutButton button clicked")
        await Auth.signOut().catch((error) => { console.log('ERROR logging out: ', error) })
        return true;
    });

    $('#MessageInput').on("keydown", function(e) {
        if (e.key === "Enter") {
            console.log('Enter');
            $('#SendButton').trigger("click");
        }
    })

    $('#MessageInput').on("focus click", function(e) {
        console.log('MessageInput focus');
        ScrollToEndOfChat();
    })

    $('#SendButton').on("click", async function(e) {
        console.log("SendButton clicked")

        AllowInput(false)

        // read contents of message input
        let message = $('#MessageInput').val()
        $("#MessageInput").val("")

        AddToChat(false, message, Date.now()/1000)
        ScrollToEndOfChat()

        let spinner = longOperationManager.begin()
        API.post('DialogueApi',
            `/conversations/id/${currentConversationId}`,
            { body: { text: message } }
        ).then(async function (response) {
            console.log(response)
            console.assert(response.message_id === lastMessageId + 1, "message_id in API response does not match UI message id!")
            AddToChat(true, response.text, Date.now()/1000)
        }).finally(() => {
            InitializeChatHandlers()
            AllowInput(true)
            spinner.end()
        })
    });

    $('[id=NewButton]').on("click", async function(e) {
        console.log("NewButton clicked")

        let spinner = longOperationManager.begin()
        API.post('DialogueApi',
            `/conversations/new`
        ).then(async function (response) {
            console.log(response)
            await PopulateConversations()
            await ChangeCurrentConversationTo(response.id)
        }).finally(() => {
            spinner.end()
            $("#MessageInput").trigger('focus')
        })
    });

    $('#HomeButton').on("click", function(e) {
        console.log("HomeButton clicked")

        $("#MessageInput").trigger('focus')
        return false
    });

    $('#ConversationHistoryModal').on('shown.bs.modal', function (e) {
        $('#SearchInput').trigger('focus')
    })

    $('#ConversationHistoryModal').on('hidden.bs.modal', function (e) {
        // HACK: timeout to allow modal to close before setting focus back to message input
        setTimeout(function(){
           $("#MessageInput").trigger('focus')
        },100);
    })

    $('#ShareModal').on('show.bs.modal', function (e) {
        // if currentSharedConversationId is non-null, then we are showing a shared conversation already
        if (currentSharedConversationId)
        {
            $('#GenerateShareURLButton').addClass("d-none")
            $('#GeneratedURL').removeClass("d-none")

            $('#ShareURL').val(window.location)
        }
        // if currentConversationId is non-null, then we are showing a private conversation
        else if (currentConversationId)
        {
            $('#GenerateShareURLButton').removeClass("d-none")
            $('#GeneratedURL').addClass("d-none")
        }
    })

    $('#ShareModal').on('shown.bs.modal', function (e) {
        // if sharing a shared conversation, then automatically copy the URL to the clipboard
        if (currentSharedConversationId)
        {
            $('#ShareCopyButton').trigger('click')
        }
    })


    $('#GenerateShareURLButton').on("click", function(e) {
        let spinner = longOperationManager.begin()
        API.post('DialogueApi',
            `/conversations/shorten/${currentConversationId}`
        ).then(async function (response) {
            console.log(response)
            if (!('sharing_url' in response))
                throw new Error("no sharing_url in response")

            $('#GenerateShareURLButton').addClass("d-none")
            $('#GeneratedURL').removeClass("d-none")

            let generatedURL = window.location.origin + response.sharing_url
            $('#ShareURL').val(generatedURL)
            $('#ShareCopyButton').trigger('click')
        }).catch(err => {
            console.log(err)
            $(this).popover('show');
        }).finally(() => {
            spinner.end()
        })
    })

    $('#ShareCopyButton').on("click", function(e) {
        // copy generatedURL to the clipboard
        window.navigator.clipboard.writeText($('#ShareURL').val())

        $(this).popover('show');
        setTimeout(() => $('.popover').popover('hide'),
                   1000);
    })

    // {
    //     Storage.list('', {level: 'public'})
    //         .then(result => {console.log("List public conversations:"); console.log(result)})
    //         .catch(err => console.log(err));
    //     Storage.list('', {level: 'private'})
    //         .then(result => {console.log("List private conversations:"); console.log(result)})
    //         .catch(err => console.log(err));

    //     Storage.get("conversations/36u0i39v", { download: true, level: 'public' })
    //         .then(result => {
    //             result.Body.text().then(string => {
    //                 console.log(string)
    //             });
    //         })
    //         .catch(error => {
    //             console.log(error)
    //         })
    // }

    if (currentSharedConversationId)
    {
        await ChangeCurrentConversationToShared(currentSharedConversationId)
    }

    // do this for the first time we render the page, user may be logged in already or not
    await Auth.currentAuthenticatedUser().then(user => {
        UpdateLoggedInState(user)
        console.log('user was already logged in')
    }).catch(() => {
        UpdateLoggedInState(null)
        console.log('no user signed in')
    })

    Hub.listen('auth', async ({ payload: { event, data } }) => {
        switch (event) {
            case 'signIn':
//            case 'cognitoHostedUI':
                console.log('sign in', event)
                await UpdateLoggedInState(data)
                break;
            case 'signOut':
            case 'oAuthSignOut':
                console.log('sign out')
                await UpdateLoggedInState(null)
                break;
            case 'signIn_failure':
            case 'cognitoHostedUI_failure':
                console.log('sign in ERROR', data);
                await UpdateLoggedInState(null)
                break;
        }
    })
}
