// this is a little bit nasty to read, but it allows use of the form:
// mgr = new LongOperationManager()
// handle = mgr.begin()
//   nestedhandle = mgr.begin()
//      ...perform long operations...
//   nestedhandle.end()
// handle.end()
// NOTE: these do NOT need to be strictly nested, but .end() must be called for each .begin()

function LongOperationManager(spinnerID, timeout = 2000) {
    this.stack = []
    this.shown = false
    this.timeout = timeout
    this.id = spinnerID
}

LongOperationManager.prototype.begin = function () {
    let longOperationManager = this
    function Handle() {
        this.end = function() {
            console.log(`LongOperationManager: ended timer ${this.timer} (stack size: ${longOperationManager.stack.length})`)
            let i = longOperationManager.stack.indexOf(this.timer)
            console.assert(i >= 0, `LongOperationManager: timer ${this.timer} not in stack`)
            if (i >= 0) {
                longOperationManager.stack.splice(i, 1)
            }
            clearTimeout(this.timer)

            if (longOperationManager.stack.length == 0 && longOperationManager.shown)
            {
                $("#" + longOperationManager.id).off('click').attr('style', 'display: none');
                longOperationManager.shown = false
            }
        }
        // if operation is not over in n seconds, show a spinner
        this.timer = setTimeout(() => {
            console.log(`LongOperationManager: timeout (was ${longOperationManager.shown})`)
            if (!longOperationManager.shown)
            {
                longOperationManager.shown = true
                // show spinner and eat all input
                $("#" + longOperationManager.id).attr('style', 'display: flex').on('click', (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    return false;
                });
                // $(document).bind('contextmenu', (e) => {
                //     e.stopPropagation();
                //     e.preventDefault();
                //     e.stopImmediatePropagation();
                //     return false;
                // });
            }
        }, longOperationManager.timeout)
        longOperationManager.stack.push(this.timer)
        console.log(`LongOperationManager: started timer ${this.timer} (stack size: ${longOperationManager.stack.length})`)
    }
    return new Handle()
}

export default LongOperationManager
